import { Button } from "../elements";
import classes from "./TeamSection.module.scss";
import TeamSlider from "./TeamSlider";

export default function TeamSection(props) {
  return (
    <section className={classes.team}>
      <div className={classes.inner}>
        <div
          className={classes.sliderCol}
          data-sal="fade"
          data-sal-delay="800"
          data-sal-easing="ease"
          data-sal-duration="1000">
          <TeamSlider />
        </div>
        <div className={classes.textInner}>
          <div className={classes.textCol}>
            <h2
              className={classes.heading}
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-easing="ease">
              The Team That <span>Makes It Happen</span>
            </h2>
            <div
              data-sal="slide-up"
              data-sal-delay="400"
              data-sal-easing="ease">
              <p>
                Software engineers, web developers, UX designers, project
                managers, marketing &amp; accounting specialists… Since we were
                founded, our greatest pride has always been our team.{" "}
              </p>
              <p>
                We hire the best. And we make sure they know this is how we feel
                about them. Click on the button below and learn about our unique
                code of work, the multitude of perks &amp; benefits we offer,
                and… if we’re currently{" "}
                <strong>looking for someone like you!</strong>
              </p>
              <Button href="/careers#jobs" className={classes.joinusButton}>
                See job offers
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
