import { motion } from "framer-motion";
import Image from "next/image";
import mainImage from "../../public/images/home-what-img1.jpg";
import classes from "./WhatSection.module.scss";

export default function WhatSectionTop() {
  return (
    <section className={classes.whatTop}>
      <motion.div className={classes.headingWrapper}>
        <h2
          className={classes.heading}
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease"
        >
          We are always busy{" "}
          <span data-check="bestbetter">making the best better</span>
        </h2>
      </motion.div>
      <motion.div
        className={classes.mainImgWrapper}
        data-sal="fade"
        data-sal-delay="400"
        data-sal-easing="ease"
        data-sal-duration="1000"
      >
        <Image src={mainImage} alt="" placeholder="blur" quality={90} />
      </motion.div>
    </section>
  );
}
