import HeroSection from "./HeroSection";
import WhatSection from "./WhatSection";
import HomeSection from "./HomeSection";

import classes from "./HomePageComponent.module.scss";
import ProductsSection from "./ProductsSection";
import TeamSection from "./TeamSection";
import CentralnicSection from "./CentralnicSection";

import { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { setLoaderShown } from "/store/ui/badge-store";
import TestimonialsSection from "../elements/TestimonialsSection";

const HomeComponent = (props) => {
  const homeBadgeLoaded = useSelector((state) => state.badge.homeBadgeLoaded);

  const dispatch = useDispatch();

  useEffect(() => {
    if (homeBadgeLoaded) {
      dispatch(setLoaderShown(true));
    }
  }, [dispatch, homeBadgeLoaded]);

  return (
    <div className={classes.homeLayout}>
      <div className={classes.home}>
        <HomeSection sectionComponent={<HeroSection />} sectionIndex={0} />
        {homeBadgeLoaded && (
          <>
            <WhatSection />
            {/* <WhatSection /> has TWO data-section's inside */}
            <HomeSection
              sectionComponent={<ProductsSection />}
              sectionIndex={3}
            />
            <HomeSection sectionComponent={<TeamSection />} sectionIndex={4} />
            <HomeSection
              sectionComponent={<TestimonialsSection version={1} />}
              sectionIndex={5}
            />
            <HomeSection
              sectionComponent={<CentralnicSection />}
              sectionIndex={6}
            />
          </>
        )}
      </div>
    </div>
  );
};

// HomeComponent.whyDidYouRender = true;
export default HomeComponent;
