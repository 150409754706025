import Image from "next/image";

import ft2017AwardLogo from "../../public/images/awards-ft-2017-logo.svg";
import ft2018AwardLogo from "../../public/images/awards-ft-2018-logo.svg";
import ft2019AwardLogo from "../../public/images/awards-ft-2019-logo.svg";
import ft2022AwardLogo from "../../public/images/awards-ft-2022-logo.svg";
import bas2019AwardLogo from "../../public/images/awards-bas-2019-logo.svg";
import affliftBtt2021AwardLogo from "../../public/images/awards-afflift-btt-2021.svg";
import iabLogo from "../../public/images/awards-iab.svg";
// import affliftBtt2020AwardLogo from "../../public/images/awards-afflift-btt-2020.svg";
// import bestcamp20AwardLogo from "../../public/images/awards-bestcamp-20-logo.svg";

import classes from "./AwardBox.module.scss";
import { useSelector } from "react-redux";

import { motion } from "framer-motion";
import { slideUpShowAnimation } from "../animations";

export default function AwardBox(props) {
  const homeBadgeLoaded = useSelector((state) => state.badge.homeBadgeLoaded);

  return (
    <motion.div
      initial="hidden"
      animate={homeBadgeLoaded && "visible"}
      variants={slideUpShowAnimation(1.5)}
      className={classes.awardBox}
    >
      <Image src={ft2017AwardLogo} alt="" />
      <Image src={ft2018AwardLogo} alt="" />
      <Image src={ft2019AwardLogo} alt="" />
      <Image src={ft2022AwardLogo} alt="" />
      <Image src={bas2019AwardLogo} alt="" />
      <Image src={affliftBtt2021AwardLogo} alt="" />
      <Image src={iabLogo} alt="" />
    </motion.div>
  );
}
