import classes from "./HeroSection.module.scss";
import AwardBox from "./AwardBox";
import { motion } from "framer-motion";
import { slideUpShowAnimation } from "../animations";
import Image from "next/image";
import {
  useIsBelowDesktop,
  useIsTablet,
  useIsNotMobile,
} from "/hooks/useMediaQuery";
import cn from "classnames";
import { useSelector } from "react-redux";
import HeroAnimText from "./HeroAnimText";

import HomeMobileBadge from "./HomeMobileBadge";
import HomeBadge from "./HomeBadge";

import morphMobileBlue from "../../public/images/mobile-morphs/home/hero-blue.svg";
import morphMobileYellow from "../../public/images/mobile-morphs/home/hero-yellow.svg";

const HeroSection = (props) => {
  const homeBadgeLoaded = useSelector((state) => state.badge.homeBadgeLoaded);
  const isTablet = useIsTablet();
  const isBelowDesktop = useIsBelowDesktop();
  const isDesktop = useIsNotMobile();

  return (
    <section className={classes.hero} id="heroHome">
      <div className={classes.inner}>
        {isBelowDesktop && (
          <>
            <Image
              src={morphMobileBlue}
              alt=""
              priority
              className={cn(classes.morphMobile, classes.morphMobileBlue)}
            />
            <Image
              src={morphMobileYellow}
              alt=""
              priority
              className={cn(classes.morphMobile, classes.morphMobileYellow)}
            />
          </>
        )}
        <div className={classes.headingWrapper}>
          <h1 className={classes.heading}>
            <motion.span
              initial="hidden"
              whileInView={homeBadgeLoaded && "visible"}
              viewport={{ once: true }}
              variants={slideUpShowAnimation(0.2)}
            >
              Game-Changing Tools
            </motion.span>
            <motion.span
              initial="hidden"
              whileInView={homeBadgeLoaded && "visible"}
              viewport={{ once: true }}
              variants={slideUpShowAnimation(0.4)}
              className={classes.outline}
            >
              <span>for</span>{" "}
              {homeBadgeLoaded && (
                <HeroAnimText
                  texts={[
                    "Digital Marketers",
                    "Affiliate Marketers",
                    "Media Buyers",
                  ]}
                ></HeroAnimText>
              )}
            </motion.span>
          </h1>

          <motion.h2
            className={classes.subheading}
            initial="hidden"
            whileInView={homeBadgeLoaded && "visible"}
            viewport={{ once: true }}
            variants={slideUpShowAnimation(0.6)}
          >
            Commerce Media Tech creates innovative software for effective online&nbsp;advertising.
          </motion.h2>
        </div>

        {isTablet && <HomeMobileBadge />}
        {isDesktop && <HomeBadge />}
        <AwardBox />
      </div>
    </section>
  );
};

// HeroSection.whyDidYouRender = true;
export default HeroSection;
