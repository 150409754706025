import Image from "next/image";
import classes from "./WhatSection.module.scss";
import cn from "classnames";
import whatImage from "../../public/images/home-what-img3.jpg";
import morphMobileWhatBlue from "../../public/images/mobile-morphs/home/what-blue.svg";

export default function ContentSideImage(props) {
  return (
    <section className={cn(classes.whatis)}>
      <h2
        className=""
        data-sal="slide-up"
        data-sal-delay="300"
        data-sal-easing="ease">
        What is <span>Commerce&nbsp;Media Tech?</span>
      </h2>

      <div
        className={classes.imgCol}
        data-sal="zoom-in"
        data-sal-delay="900"
        data-sal-easing="ease"
        data-sal-duration="1000">
        <div
          ref={props.whatImgRef}
          className={cn(classes.imgWrapper, {
            [classes.isInview]: props.whatImgInView,
          })}>
          <Image src={whatImage} alt="" placeholder="blur" quality={85} />

          <svg
            className={classes.imgBorder}
            viewBox="0 0 786 542"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.00398 24.9999L1.0002 517C1.0001 530.255 11.7453 541 25.0002 541L715.098 541L760.995 541C774.25 541 784.995 530.255 784.995 517L785 25.0004C785 11.7455 774.255 1.0002 761 1.0002L25.004 1.00009C11.7492 1.00008 1.00409 11.7451 1.00398 24.9999Z"
              stroke="black"
            />
          </svg>

          <span
            className={cn(classes.morphMobile, classes.morphMobileBlueBottom)}>
            <Image src={morphMobileWhatBlue} alt="" />
          </span>
        </div>
      </div>
      <div
        className={classes.textCol}
        data-sal="slide-up"
        data-sal-delay="400"
        data-sal-easing="ease">
        <p>
          We started out in 2010 in Kraków, Poland with the goal to create
          custom software for our founder, an independent digital marketer.
        </p>
        <p>
          Exceeding even his expectations, our products quickly became popular
          among thousands of{" "}
          <strong>
            affiliate marketers &amp;&nbsp;performance advertising agencies.
          </strong>
        </p>
        <p>
          Today, we pride ourselves with providing industry-leading tools,
          support and education to our clients around the globe.{" "}
          <strong>And we enjoy every minute of it!</strong>
        </p>
      </div>
    </section>
  );
}
