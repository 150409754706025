import Image from "next/image";
import classes from "./WhatSection.module.scss";
import cn from "classnames";

import { useIsBelowDesktop } from "../../hooks/useMediaQuery";
import { useInView, InView } from "react-intersection-observer";

import morphMobileWeBlue from "../../public/images/mobile-morphs/home/we-blue.svg";

import WhatSectionTop from "./WhatSectionTop";
import WhatSectionContent from "./WhatSectionContent";
import HomeSection from "./HomeSection";

export default function WhatSection(props) {
  const isBelowDesktop = useIsBelowDesktop();

  const [whatImgRef, whatImgInView, whatImgEntry] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  return (
    <section className={classes.what}>
      {isBelowDesktop && (
        <>
          <span className={cn(classes.morphMobile, classes.morphMobileBlue)}>
            <Image src={morphMobileWeBlue} alt="" />
          </span>
        </>
      )}
      <div className={classes.inner}>
        <HomeSection
          sectionComponent={<WhatSectionTop />}
          sectionIndex={1}
          id="explore"
        />
        <HomeSection
          sectionComponent={
            <WhatSectionContent
              whatImgRef={whatImgRef}
              whatImgInView={whatImgInView}
            />
          }
          sectionIndex={2}
        />
      </div>
    </section>
  );
}
