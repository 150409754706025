import { InView } from "react-intersection-observer";
import { useSelector, useDispatch } from "react-redux";
import { setCurrentSection } from "/store/ui/home-store";


const HomeSection = ({ sectionComponent, sectionIndex, ...props }) => {
  const dispatch = useDispatch();

  const onSectionInView = (inView, entry, sectionNumber) => {
    if (inView) {
      dispatch(setCurrentSection(sectionNumber)); // TODO 0 fix this
    }
  };

  return (
    <InView
      as="div"
      threshold={0}
      onChange={(inView, entry) => {
        onSectionInView(inView, entry, sectionIndex);
      }}
      rootMargin="0px 0px 0px 0px"
      data-section={sectionIndex}
      {...props}
    >
      {sectionComponent}
    </InView>
  );
};

export default HomeSection;
