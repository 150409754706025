import Image from "next/image";
import classes from "./CentralnicSection.module.scss";

import centralnicBg from "../../public/images/home-centralnic-bg.png";
import centralnicLogo from "../../public/images/home-team-internet-logo3.svg";

export default function CentralnicSection(props) {
  return (
    <section className={classes.centralnic}>
      <Image
        src={centralnicBg}
        alt=""
        className={classes.bg}
        placeholder="blur"
        fill
      />
      <div className={classes.inner}>
        <div className={classes.textCol}>
          <h2
            className={classes.heading}
            data-sal="slide-up"
            data-sal-delay="600"
            data-sal-easing="ease"
          >
            A Part of <span>Something Bigger</span>
          </h2>
          <div data-sal="slide-up" data-sal-delay="800" data-sal-easing="ease">
            <p>
              In 2020 we joined the Team Internet Group – a multinational holding
              company specialising in domain name registry, web hosting, domain
              parking, and online traffic monetization.
            </p>
            <p>
              Together we strive to make the{" "}
              <strong>potential of the internet available to anyone</strong> who
              wants to use it for creating something exciting &amp; new.
            </p>
          </div>
        </div>
        <div className={classes.imgCol}>
          <Image
            src={centralnicLogo}
            alt="Team Internet Group PLC"
            className={classes.centralnicLogo}
            data-sal="fade"
            data-sal-delay="300"
            data-sal-easing="ease"
            data-sal-duration="500"
          />
        </div>
      </div>
    </section>
  );
}
