import Image from "next/image";

import React, { useEffect, useState } from "react";
import cn from "classnames";
import classes from "./HomeMobileBadge.module.scss";
import badgeStyles from "../layout/BadgeStyles.module.scss";

import { gsap } from "../../gsap";

import BadgeCounter from "./BadgeCounter";

import exploreBadgeImg from "../../public/images/badge-explore-cmtech.svg";
import welcomeBadgeImg from "../../public/images/badge-explore-cmtech.svg";

import { useSelector, useDispatch } from "react-redux";
import { setHomeBadgeLoaded } from "../../store/ui/badge-store";
import { scrollToEase } from "/helpers/constants";
import { useIsTablet } from "/hooks/useMediaQuery";

import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";

export default function HomeMobileBadge({ fixed, atBottom }) {
  const [badgeImage, setBadgeImage] = useState(welcomeBadgeImg);
  // const [badgeLink, setBadgeLink] = useState("#explore");

  const loaderShown = useSelector((state) => state.badge.loaderShown);
  const counterFinished = useSelector((state) => state.badge.counterFinished);
  const homeBadgeLoaded = useSelector((state) => state.badge.homeBadgeLoaded);

  const dispatch = useDispatch();
  const isTablet = useIsTablet();

  const handleClick = (e) => {
    e.preventDefault();

    if (!fixed) {
      const header = document.querySelector("#header");
      let headerHeight = header?.getBoundingClientRect()?.height ?? 0;
      let duration = 0.65;

      if (isTablet) {
        headerHeight += 5;
        duration = 0.45;
      }

      gsap.to(window, 0.65, {
        scrollTo: { y: "#explore", offsetY: headerHeight, ease: scrollToEase }
      });
    }
  };

  useEffect(() => {

    let timeout;

    if (counterFinished) {
      timeout = setTimeout(() => {
        dispatch(setHomeBadgeLoaded(true));
      }, 700);
    }

    return () => {
      clearTimeout(timeout);
    }
  }, [dispatch, counterFinished]);

  useEffect(() => {
    if (loaderShown) {
      setBadgeImage(exploreBadgeImg);
    }
  }, [loaderShown]);

  useEffect(() => {
    const badgeContainer = document.querySelector("#badgeContainer");

    if (homeBadgeLoaded) {
      enableBodyScroll(badgeContainer);
    } else {
      disableBodyScroll(badgeContainer);
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [homeBadgeLoaded]);

  return (
    <div className={classes.loaderLayer} id="loaderlayer">
      <div
        id="badgeContainer"
        className={cn(classes.badgeContainer)}
      >
        <div className={classes.badgeWrapper}>
          <div
            className={cn(badgeStyles.badge, classes.wrapper, {
              [classes.counterFinished]: counterFinished || loaderShown,
            })}
          >
            <div
              className={cn(
                badgeStyles.badgeImageWrapper,
                classes.imageWrapper
              )}
            >
              {!loaderShown && (
                <BadgeCounter className={classes.counterWrapper} />
              )}
              <Image
                src={badgeImage}
                fill
                alt=""
                priority
                onClick={handleClick}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
