import Image from "next/image";
import { Navigation, Keyboard } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import classes from "./TeamSlider.module.scss";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/keyboard";

import sliderImg1 from "../../public/images/home-team-slider-img1.jpg";
import sliderImg2 from "../../public/images/home-team-slider-img2.jpg";
import sliderImg3 from "../../public/images/home-team-slider-img3.jpg";
import sliderImg4 from "../../public/images/home-team-slider-img4.jpg";
import sliderImg5 from "../../public/images/home-team-slider-img5.jpg";

import { v4 as uuidv4 } from "uuid";
import { useEffect, useRef, useMemo } from "react";
import { useIsTablet } from "../../hooks/useMediaQuery";
import cn from "classnames";

export default function TeamSlider(props) {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const isMobile = useIsTablet();

  const slidesArray = useMemo(() => {
    return [sliderImg1, sliderImg2, sliderImg3, sliderImg4, sliderImg5];
  }, []);

  const slides = useRef();
  slides.current = slidesArray;

  useEffect(() => {
    if(isMobile) {
      slides.current = slidesArray;
    } else {
      slides.current = slidesArray.reverse();
    }
  }, [isMobile, slidesArray])

  return (
		<div className={classes.sliderWrapper}>
			<Swiper
				speed={700}
				modules={[Navigation]}
				breakpoints={{
					478: {
						slidesPerView: "auto",
					},
					768: {
						slidesPerView: "auto",
					},
					960: {
						spaceBetween: 42,
						slidesPerView: "auto",
					},
					1152: {
						slidesPerView: "auto",
					},
					1921: {
						slidesPerView: "auto",
					},
				}}
				spaceBetween={16}
				navigation={{
					nextEl: nextRef.current,
					prevEl: prevRef.current,
					disabledClass: classes.swiperNavigationButtonDisabled,
				}}
				keyboard
				slidesPerView={1}
				onInit={(swiper) => {
					swiper.params.navigation.nextEl = nextRef.current;
					swiper.params.navigation.prevEl = prevRef.current;
					swiper.navigation.init();
					swiper.navigation.update();

					if (window.matchMedia("screen and (min-width: 1152px)").matches) {
						swiper.slideTo(slides.current.length);
					}
				}}
				className={classes.swiperContainer}>
				<SwiperSlide className={cn(classes.swiperSlide, classes.swiperSlideEmpty)}></SwiperSlide>
				{slides.current.map((slideImg, index) => (
					<SwiperSlide key={index} className={classes.swiperSlide}>
						<Image src={slideImg} alt="" placeholder="blur" quality={80} className={classes.slideImg} />
					</SwiperSlide>
				))}

				<div className={classes.swiperNavigationWrapper}>
					<div
						ref={prevRef}
						className={classes.swiperNavigationPrev}
						//   tabIndex={0}
					></div>
					<div
						ref={nextRef}
						className={classes.swiperNavigationNext}
						//   tabIndex={0}
					></div>
				</div>
			</Swiper>
		</div>
	);
}
