import HomePageComponent from "../components/home/HomePageComponent";
import {NextSeo} from "next-seo";

export default function Home() {
  return (
    <>
      <NextSeo
        title="Commerce Media Tech"
        description="Game-Changing Tools for Digital Marketers"
        openGraph={{
          title: "Commerce Media Tech",
          description: "Game-Changing Tools for Digital Marketers",
          images: [
            {
              url: "/images/cmt-og.png",
              width: 1200,
              height: 630,
              alt: "Commerce Media Tech",
              type: "image/png",
            },
          ],
        }}
      />
      <HomePageComponent />
    </>
  );
}