import Image from "next/image";

import React, { useEffect, useState } from "react";
import cn from "classnames";
import classes from "./HomeBadge.module.scss";
import badgeStyles from "../layout/BadgeStyles.module.scss";

import BadgeCounter from "./BadgeCounter";

import exploreBadgeImg from "../../public/images/badge-explore-cmtech.svg";
import welcomeBadgeImg from "../../public/images/badge-explore-cmtech.svg";

import { useSelector, useDispatch } from "react-redux";
import { setHomeBadgeLoaded } from "../../store/ui/badge-store";
import { scrollToEase } from "/helpers/constants";
import { useIsTablet } from "/hooks/useMediaQuery";
import throttle from "lodash/throttle";
import { badgeScrollToSection } from "/helpers/badgeHelpers";

import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";

const HomeBadge = ({ fixed }) => {
  const [badgeImage, setBadgeImage] = useState(welcomeBadgeImg);
  const [isBadgeFixed, setIsBadgeFixed] = useState(false);

  // const [badgeLink, setBadgeLink] = useState("#explore");

  const isBadgePreBottom = useSelector((state) => state.badge.isAtPreBottom);
  const loaderShown = useSelector((state) => state.badge.loaderShown);
  const counterFinished = useSelector((state) => state.badge.counterFinished);
  const homeBadgeLoaded = useSelector((state) => state.badge.homeBadgeLoaded);
  // const badgeIsAtPreBottom = useSelector((state) => state.badge.isAtPreBottom);

  const currentSection = useSelector((state) => state.home.currentSection);

  const dispatch = useDispatch();
  const isTablet = useIsTablet();

  const handleScroll = (e) => {
    if (window.scrollY > 0 && window.innerWidth >= 1536) {
      setIsBadgeFixed(true);
    } else {
      setIsBadgeFixed(false);
    }
  };

  const throttledScrollHandler = throttle(handleScroll, 200);

  useEffect(() => {
    throttledScrollHandler();

    window.addEventListener("scroll", throttledScrollHandler, {
      passive: true,
    });

    // cleanup
    return () => {
      window.removeEventListener("scroll", throttledScrollHandler);
    };
  }, [isBadgeFixed, throttledScrollHandler]);

  const handleClick = (e) => {
    e.preventDefault();
    badgeScrollToSection({
      isBadgePreBottom,
      isBadgeFixed,
      isTablet,
      currentSection,
      badgeType: "home",
    });
  };

  useEffect(() => {
    let timeout;

    if (counterFinished) {
      timeout = setTimeout(() => {
        dispatch(setHomeBadgeLoaded(true));
      }, 700);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [dispatch, counterFinished]);

  useEffect(() => {
    if (loaderShown) {
      setBadgeImage(exploreBadgeImg);
    }
  }, [loaderShown]);

  useEffect(() => {
    const badgeContainer = document.querySelector("#badge");

    if (homeBadgeLoaded) {
      enableBodyScroll(badgeContainer);
    } else {
      disableBodyScroll(badgeContainer);
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [homeBadgeLoaded]);

  return (
    <div className={classes.loaderLayer}>
      <div
        id="badgeContainer"
        className={cn(classes.badgeContainer, {
          [classes.badgeContainerAtPreBottom]: isBadgePreBottom,
          [classes.badgeContainerFixed]: isBadgeFixed,
        })}
      >
        <div className={classes.badgeWrapper}>
          <div
            className={cn(badgeStyles.badge, classes.wrapper, {
              [classes.counterFinished]: counterFinished || loaderShown,
              [classes.badgeFixed]: homeBadgeLoaded && fixed,
              [badgeStyles.atPreBottom]: isBadgePreBottom,
            })}
            id="badge"
          >
            <div
              className={cn(
                badgeStyles.badgeImageWrapper,
                classes.imageWrapper
              )}
            >
              {!loaderShown && (
                <BadgeCounter className={classes.counterWrapper} />
              )}
              <Image
                src={badgeImage}
                fill
                alt=""
                priority
                onClick={handleClick}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// HomeBadge.whyDidYouRender = true;
export default HomeBadge;
