import { motion, AnimatePresence } from "framer-motion";
import cn from "classnames";
import classes from "./HeroAnimText.module.scss";
import { useEffect, useState } from "react";
import { gsap } from "../../gsap";
import { useRef } from "react";

// ==== hero text animation

const textContainer = {
  hidden: {
    y: 20,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const textChild = {
  hidden: { opacity: 0 },
  visible: {
    opacity: [0, 1, 1, 0],
    y: [9, 0, 0, 9],
    // opacity: [0, 1, 1, 1, 1, 1, 1, 0],
    // y: [9, 0, 0, 0, 0, 0, 9],
    transition: {
      repeatDelay: 4,
      duration: 2,
      repeat: Infinity,
      ease: [0.3, 0.76, 0.27, 1],
    },
  },
};

export default function HeroAnimText({ texts }) {
  const tl = useRef();

  useEffect(() => {
    tl.current = gsap.timeline({
      repeat: -1,
    });

    tl.current.to(".text", {
      keyframes: [
        {
          y: 0,
          opacity: 1,
          duration: 0.4,
        },
        {
          y: 0,
          opacity: 1,
          duration: 1.5,
        },
        {
          y: -9,
          opacity: 0,
          duration: 0.2,
          delay: -0.2,
        },
      ],
      stagger: 1.8,
      ease: "power2.out",
    });
  }, []);

  return (
    <span className={cn(classes.outline, classes.slideTextAnimContainer)}>
      {texts &&
        texts.map((text, index) => (
          <span
            key={text}
            className={cn(classes.slideTextAnimText, "text")}
            style={{ "--animation-order": index }}
          >
            {text}
          </span>
        ))}
      <span className={classes.slideTextAnimFiller}>Affiliate Marketers</span>
      {/* TODO 1 put above longest word automatically */}
    </span>
  );
}
