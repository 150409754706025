import { useState, useEffect } from "react";
import cn from "classnames";

import { useDispatch } from 'react-redux';
import { setCounterFinished } from "../../store/ui/badge-store";

export default function BadgeCounter({ className }) {
  const [counter, setCounter] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setInterval(() => {
      setCounter((prevCount) => {
        if (prevCount < 100) {
          return prevCount + 1;
        }
        clearInterval(timer);

        setTimeout(() => {
          dispatch(setCounterFinished(true));
        }, 0);

        return prevCount;
      });
    }, 5);

    // cleanup function
    return () => {
      clearInterval(timer);
    };
  }, [dispatch]); // [] - run effect only once to prevent re-rendering

  return <div className={cn(className)}>{counter}%</div>;
}
